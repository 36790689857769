
import { Options, Vue } from 'vue-class-component';
import { BlockchainServices } from '@/services/blockchainServices';
import { MerlinFundClient, MerlinFundPriceClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import { ethers } from 'ethers';
import { connectMetamask } from '@/utils';
import ConnectWalletModal from '../modals/connectWalletModal.vue';
import store from '@/store';
import * as VM from '@/viewModel';

@Options({
    components: {
    }
})


export default class Home extends Vue {

    filters: number[] = [];
    fundsList: VM.Fund[] = [];
    fundsFromDb: string[] = [];

    isConnected: boolean = false;
    initAlreadyCalled: boolean = false;

    sortDirection:string =  'asc';
    loaded: boolean = false;
    
    created() {
        this.filters.push(0);
        this.init();
        this.applySorting(0);
    }

    applySorting(casefound: number) {
        var sortBy = casefound;
        if (sortBy) {
            this.fundsList.sort((a, b) => {
            let aValue, bValue;

            // Determina il valore da confrontare in base alla colonna selezionata
            switch (sortBy) {
                case 0:
                aValue = a.title.toLowerCase();
                bValue = b.title.toLowerCase();
                break;
                case 1:
                aValue = a.marketCap;
                bValue = b.marketCap;
                break;
                case 2:
                aValue = a.price;
                bValue = b.price;
                break;
                case 3:
                aValue = a.lastYearPercentage;
                bValue = b.lastYearPercentage;
                break;
                case 4:
                aValue = a.lastMonthPercentage;
                bValue = b.lastMonthPercentage;
                break;
                case 5:
                aValue = a.lastWeekPercentage;
                bValue = b.lastWeekPercentage;
                break;
                case 6:
                aValue = a.yesterdayPercentage;
                bValue = b.yesterdayPercentage;
                break;
                default:
                aValue = 0;
                bValue = 0;
                break;
            }

            // Ordina in ordine alfabetico
            if (sortBy === 0) {
                if (this.sortDirection === 'asc') {
                return aValue.localeCompare(bValue);
                } else {
                return bValue.localeCompare(aValue);
                }
            }

            // Altrimenti, applica l'ordinamento in base alla direzione
            if (this.sortDirection === 'asc') {
                return aValue - bValue;
            } else {
                return bValue - aValue;
            }
            });
        }
    }

    randomBgColor(title: "") {
        var colors = ["#CDD0D9", "#ffb02e", "#0dcaf0", "#20c997", "#f8f9fa", "#fff"];
        var index = Math.floor(Math.random() * 6);

        return colors[index];
    }

    async init() {
        var checkIfConnected = await BlockchainServices.connectedMetamask();

        if(checkIfConnected) {
            this.isConnected = true;
            store.state.walletAddress = window.ethereum.selectedAddress;
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            var signer = provider.getSigner();
            this.getAllFunds(signer);
        }
        else {
            this.$opModal.show(ConnectWalletModal, {
                connectToMetamask: async () => {
                    var ris = connectMetamask();
                    this.$opModal.closeLast();
                },
                closeModal: () => {
                    this.$opModal.closeLast();
                }
            })
        }
    }

    @Watch('$store.state.walletAddress', { deep: true })
    checkIfAccountConnected(){
        if(store.state.walletAddress && this.fundsList.length == 0)
            this.init();
    }

    async getAllFunds(provider: ethers.providers.JsonRpcSigner) {

        if(this.initAlreadyCalled)
            return;
        else
            this.initAlreadyCalled = true;

        MerlinFundClient.getAllFunds()
        .then(x => {
            this.fundsFromDb = x;

            x.forEach( async address => {
                var fundItem = new VM.Fund();
                fundItem.contractAddress = address;
            
                fundItem.imageUrl = await BlockchainServices.getSymbol(address, provider);
                fundItem.title = await BlockchainServices.getName(address, provider);
                fundItem.price = await BlockchainServices.getFundPriceInDollar(address, provider);
                fundItem.marketCap = await BlockchainServices.getMarketCap(address, fundItem.price, provider);

                var allPricesSteps = await MerlinFundPriceClient.getFundPricesStep(address, false);
                fundItem.lastYearPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.year);
                fundItem.lastMonthPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.month);
                fundItem.lastWeekPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.week);
                fundItem.yesterdayPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.day);
                fundItem.lastYearPercentageString = (fundItem.lastYearPercentage).toFixed(2);
                fundItem.lastMonthPercentageString = (fundItem.lastMonthPercentage).toFixed(2);
                fundItem.lastWeekPercentageString = (fundItem.lastWeekPercentage).toFixed(2);
                fundItem.yesterdayPercentageString = (fundItem.yesterdayPercentage).toFixed(2);
            
                var tokensList = await BlockchainServices.getTokensInFund(address, provider);
                for(var i = 0; i < tokensList.length; i++) {
                    var tokenAddress = tokensList[i];
                    var imageUrl = await BlockchainServices.getImageSymbolUrl(tokenAddress, provider);
            
                    var tokenVM = new VM.TokenInFund();
                    tokenVM.contractAddress = tokenAddress;
                    tokenVM.imageUrl = imageUrl;
                    fundItem.tokens.push(tokenVM);
                }
                // tokensList.forEach(async tokenAddress => {
                //     var imageUrl = await BlockchainServices.getImageSymbolUrl(tokenAddress, provider);
            
                //     var tokenVM = new VM.TokenInFund();
                //     tokenVM.contractAddress = tokenAddress;
                //     tokenVM.imageUrl = imageUrl;
                //     fundItem.tokens.push(tokenVM);
                // });
            
                this.fundsList.push(fundItem);
            });
        });

        // if(this.initAlreadyCalled)
        //     return;
        // else
        //     this.initAlreadyCalled = true;

        // this.fundsFromDb = await MerlinFundClient.getAllFunds();

        // for(let i = 0; i < this.fundsFromDb.length; i ++)
        // {
        //     //Ti ho messo nelle note in cima alla classe _BlockchainServices
        //     var fundItem = new VM.Fund();
        //     let address = this.fundsFromDb[i];
        //     fundItem.contractAddress = address;
            
        //     fundItem.imageUrl = await BlockchainServices.getSymbol(address, provider);
        //     fundItem.title = await BlockchainServices.getName(address, provider);
        //     fundItem.price = await BlockchainServices.getFundPriceInDollar(address, provider);

        //     var allPricesSteps = await MerlinFundPriceClient.getFundPricesStep(address, false);

        //     fundItem.lastYearPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.year);
        //     fundItem.lastMonthPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.month);
        //     fundItem.lastWeekPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.week);
        //     fundItem.yesterdayPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.day);
        //     fundItem.lastYearPercentageString = (fundItem.lastYearPercentage).toFixed(2);
        //     fundItem.lastMonthPercentageString = (fundItem.lastMonthPercentage).toFixed(2);
        //     fundItem.lastWeekPercentageString = (fundItem.lastWeekPercentage).toFixed(2);
        //     fundItem.yesterdayPercentageString = (fundItem.yesterdayPercentage).toFixed(2);
            
        //     let tokensList = await BlockchainServices.getTokensInFund(address, provider);

        //     for(let t = 0; t < tokensList.length; t++) {
        //             var tokenAddress = tokensList[t];
        //             var imageUrl = await BlockchainServices.getImageSymbolUrl(tokenAddress, provider);
    
        //             var tokenVM = new VM.TokenInFund();
        //             tokenVM.contractAddress = tokenAddress;
        //             tokenVM.imageUrl = imageUrl;
        //             fundItem.tokens.push(tokenVM);
        //         }
        //         // tokensList.forEach(async tokenAddress => {
        //         //     var imageUrl = await BlockchainServices.getImageSymbolUrl(tokenAddress, provider);
    
        //         //     var tokenVM = new VM.TokenInFund();
        //         //     tokenVM.contractAddress = tokenAddress;
        //         //     tokenVM.imageUrl = imageUrl;
        //         //     fundItem.tokens.push(tokenVM);
        //         // });
    
        //     this.fundsList.push(fundItem);
        // }

            
        // MerlinFundClient.getAllFunds()
        // .then(x => {
        //     this.fundsFromDb = x;
            
        //     x.forEach(address => {
        //         Promise.all([
        //             BlockchainServices.getSymbol(address, provider),
        //             BlockchainServices.getName(address, provider),
        //             BlockchainServices.getFundPriceInDollar(address, provider),
        //             MerlinFundPriceClient.getFundPricesStep(address, false),
        //             BlockchainServices.getTokensInFund(address, provider)
        //         ])
        //         .then( xs => {
        //             // var fundItem = new VM.Fund();
        //             // fundItem.contractAddress = address;
                    
        //             // fundItem.imageUrl = xs[0];
        //             // fundItem.title = xs[1];
        //             // fundItem.price = xs[2];

        //             // var allPricesSteps = xs[3];
        //             // fundItem.lastYearPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.year);
        //             // fundItem.lastMonthPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.month);
        //             // fundItem.lastWeekPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.week);
        //             // fundItem.yesterdayPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.day);
        //             // fundItem.lastYearPercentageString = (fundItem.lastYearPercentage).toFixed(2);
        //             // fundItem.lastMonthPercentageString = (fundItem.lastMonthPercentage).toFixed(2);
        //             // fundItem.lastWeekPercentageString = (fundItem.lastWeekPercentage).toFixed(2);
        //             // fundItem.yesterdayPercentageString = (fundItem.yesterdayPercentage).toFixed(2);
                    
        //             var tokensList = xs[4];

        //             var proms = [];
        //             proms.push(BlockchainServices.getMarketCap(address, fundItem.price, provider));
                    
        //             tokensList.forEach( el => {
        //                 proms.push(BlockchainServices.getImageSymbolUrl(el, provider));
        //             })

        //             Promise.all(proms)
        //             .then( results => {
        //                 fundItem.marketCap = results[0];
                        
        //                 for(var i = 0; i < tokensList.length; i++){
        //                     var tokenVM = new VM.TokenInFund();
        //                     tokenVM.contractAddress = tokensList[i];
        //                     tokenVM.imageUrl = results[i + 1];
        //                     fundItem.tokens.push(tokenVM);
        //                 }

        //                 this.fundsList.push(fundItem);

        //             })
        //         }).catch( err => {
        //             console.error(err)
        //         })
        //     });

        //     // x.forEach( async address => {
        //     //     // var fundItem = new VM.Fund();
        //     //     // fundItem.contractAddress = address;
    
        //     //     // fundItem.imageUrl = await BlockchainServices.getSymbol(address, provider);
        //     //     // fundItem.title = await BlockchainServices.getName(address, provider);
        //     //     // fundItem.price = await BlockchainServices.getFundPriceInDollar(address, provider);
        //     //     // fundItem.marketCap = await BlockchainServices.getMarketCap(address, fundItem.price, provider);

        //     //     // var allPricesSteps = await MerlinFundPriceClient.getFundPricesStep(address, false);
        //     //     // fundItem.lastYearPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.year);
        //     //     // fundItem.lastMonthPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.month);
        //     //     // fundItem.lastWeekPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.week);
        //     //     // fundItem.yesterdayPercentage = this.getPercentageVaration(fundItem.price, allPricesSteps.day);
        //     //     // fundItem.lastYearPercentageString = (fundItem.lastYearPercentage).toFixed(2);
        //     //     // fundItem.lastMonthPercentageString = (fundItem.lastMonthPercentage).toFixed(2);
        //     //     // fundItem.lastWeekPercentageString = (fundItem.lastWeekPercentage).toFixed(2);
        //     //     // fundItem.yesterdayPercentageString = (fundItem.yesterdayPercentage).toFixed(2);
    
        //     //     // var tokensList = await BlockchainServices.getTokensInFund(address, provider);
        //     //     // for(var i = 0; i < tokensList.length; i++) {
        //     //     //     var tokenAddress = tokensList[i];
        //     //     //     var imageUrl = await BlockchainServices.getImageSymbolUrl(tokenAddress, provider);
    
        //     //     //     var tokenVM = new VM.TokenInFund();
        //     //     //     tokenVM.contractAddress = tokenAddress;
        //     //     //     tokenVM.imageUrl = imageUrl;
        //     //     //     fundItem.tokens.push(tokenVM);
        //     //     // }
        //     //     // tokensList.forEach(async tokenAddress => {
        //     //     //     var imageUrl = await BlockchainServices.getImageSymbolUrl(tokenAddress, provider);
    
        //     //     //     var tokenVM = new VM.TokenInFund();
        //     //     //     tokenVM.contractAddress = tokenAddress;
        //     //     //     tokenVM.imageUrl = imageUrl;
        //     //     //     fundItem.tokens.push(tokenVM);
        //     //     // });
    
        //     //     // this.fundsList.push(fundItem);
        //     // });
        // });
    }

    @Watch('fundsList.length')
    checkIfLoaded() {
        if(this.fundsList.length == this.fundsFromDb.length)
            this.loaded = true;
        else
            this.loaded = false;
    }

    getPercentageVaration(actualPrice: number, benchmarkPrice: number)
    {
        try
        {
            var variation: number = actualPrice - benchmarkPrice;
            var variationPercentage = (variation/benchmarkPrice)*100;

            return variationPercentage;
        }
        catch(ex)
        {
            console.log("Error in getPercentageVaration", ex);
        }
    }

    toggleFilter(pos: number) {
        var index = this.filters.indexOf(pos);

        if(index == -1)
            this.filters.push(pos);
        else
            this.filters.splice(index, 1);
    }

    isFilterActive(pos: number) {
        return this.filters.indexOf(pos) > -1;
    }

    async perJo()
    {
        await BlockchainServices.connectingMetaMask();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        var signer = provider.getSigner();

        var ris = await BlockchainServices.buy("0x53869F3815f414EDeb3De31c752c4e7dB9719d75", 1, signer);
        console.log(ris);
    }

    openUrl(url: string) {
        window.open(url, '_blank');
    }
}
